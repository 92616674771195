import React, { FC, useEffect, useState } from 'react'
import { api } from '../api/api'
import { useActions } from '../hooks/useAction'
import { useTypedSelector } from '../hooks/useTypedSelector'
import Notification from '../models/notification'
import { ResponseToken } from '../types/balance/responseToken'
import { Sub } from '../types/subscription/sub'
import { Subscription  } from '../types/subscription/subscription'
import { ESubscription, User } from '../types/user/user'
import styles from './css/BalancePage.module.css'

const BuyPage: FC = () => {
	const [token, setToken] = useState<ResponseToken>({} as ResponseToken)

	const { user } = useTypedSelector(x => x.user)
	const {
		current: currentLang,
		lang: { Value: lang },
	} = useTypedSelector(x => x.lang)

	const { setLoadingScreen, showConfirmation, setUser } = useActions()

	useEffect(() => {
		document.title = 'vvTable - Payments'

		const fetchApi = async () => {
			setLoadingScreen(true)

			await getSubs()
			await getTradeOfferToken()

			setLoadingScreen(false)
		}

		fetchApi()
	}, [])

	const getSubs = async () => {
		try {
			const response = await api.get<Subscription[]>('subscription/list')
			const _subs = response.data

			const newSubs: Sub[] = _subs.map(sub => {
				return {
					sub: sub.access,
					name: User.GetSubscriptionShort(sub.access),
					price: sub.price,
					discount: sub.discount,
				} as Sub
			})

		} catch {
			Notification.ShowNotification(lang.Notification_Error)
		}
	}

	const getTradeOfferToken = async () => {
		if (!token?.success) {
			try {
				const response = await api.get<ResponseToken>('balance/token')
				setToken(response.data)
			} catch {}
		}
	}

	return (
		<div>
            <div className={styles.balanceMessage}>
                <div>
                    <div className={styles.messageForUser}>
                        {{
                            0: (
                                <React.Fragment>
                                    Привет, <strong>{user.nickname}</strong>. На нашем сайте валюта - ключи из игры Team Fortress 2.
                                    <br />
                                    Для пополнения баланса <strong>отправьте ключи по трейд офферу нашему боту.</strong>
                                    <br />
                                    После того, как бот получит ключи, <strong>на ваш баланс будет зачислена сумма равная количеству ключей!</strong>
                                    <br />
                                    Если у вас нет ключей, вы также можете пополнить баланс другими способами оплаты
                                </React.Fragment>
                            ),
                        }[currentLang] || (
                            <React.Fragment>
                                Hello, <strong>{user.nickname}</strong>. On our site currency is keys from Team Fortress 2.
                                <br />
                                In order to replenish the balance, <strong>send the keys for the trade offer to our bot.</strong>
                                <br />
                                After the bot receives the keys, <strong>an amount equal to the number of keys will be credited to your balance!</strong>
                                <br />
                                If you do not have keys, you can also top up your balance using other payment methods
                            </React.Fragment>
                        )}
                    </div>
                    {/* <div className={styles.anotherPay}>
                        <span>
                            {{
                                0: <React.Fragment>Для оплаты картой пишите сюда: </React.Fragment>,
                            }[currentLang] || <React.Fragment>To pay by card write here: </React.Fragment>}
                            <a href='https://t.me/vvtable_support' target='_blank' rel='noreferrer nofollow'>
                                <strong>@vvtable_support</strong>
                            </a>
                        </span>
                    </div> */}
                    <div className={styles.balanceWarning}>
                        <span>
                            {{ 0: 'Перед тем как пополнить баланс - проверьте на каком вы домене' }[currentLang] ||
                                'Before replenishing balance - check which domain you are on'}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.balanceDeposit}>
                <div className={styles.itemDeposit}>
                    <div className={styles.depositHeader}>
                        <img src='Resources/mann_co_key.png' alt='key' className={styles.animImg} />
                    </div>
                    <div className={styles.depositFooter}>
                        <span className={styles.itemName}>Mann Co. Supply Crate Key</span>
                        {token?.token ? (
                            <a className={`${styles.depositBtn} btn-hov`} href={token.token} rel='nofollow noreferrer' target='_blank'>
                                {lang.BalancePage_DepositBtn}
                            </a>
                        ) : (
                            <span className={styles.depositBtn}>{lang.Notification_Error}</span>
                        )}
                    </div>
                </div>
                <div className={styles.itemDeposit}>
                    <div className={styles.depositHeader}>
                        <img src='Resources/payments/freekassa.png' alt='key' className={`${styles.animImg} ${styles.cryptoImg}`} />
                    </div>
                    <div className={styles.depositFooter}>
                        <span className={styles.itemName}>FreeKassa</span>
                        <a className={`${styles.depositBtn} btn-hov`} href={'/payments'} rel='nofollow noreferrer' target='_blank'>
                            {lang.BalancePage_RefillBtn}
                        </a>
                    </div>
                </div>
                <div className={styles.itemDeposit}>
                    <div className={styles.depositHeader}>
                        <img src='Resources/payments/coins.png' alt='key' className={`${styles.animImg} ${styles.cryptoImg}`} />
                    </div>
                    <div className={styles.depositFooter}>
                        <span className={styles.itemName}>{lang.BalancePage_Crypto}</span>
                        <a className={`${styles.depositBtn} btn-hov`} href={'/payments'} rel='nofollow noreferrer' target='_blank'>
                            {lang.BalancePage_RefillBtn}
                        </a>
                    </div>
                </div>
                <div className={styles.itemDeposit}>
                    <div className={styles.depositHeader}>
                        <img src='Resources/payments/vv_keys_logo.png' alt='key' className={`${styles.vvKeysLogo} ${styles.cryptoImg} ${styles.imageBackground}`} />
                        <img src='Resources/Footer/telegram.png' alt='key' className={`${styles.tgLogo} ${styles.imageOverlay}`} />
                    </div>
                    <div className={styles.depositFooter}>
                        <span className={styles.itemName}>{lang.BalancePage_TextKeys}</span>
                        <a className={`${styles.depositBtn} btn-hov`} href={'https://t.me/vvtable_keys_bot'} rel='nofollow noreferrer' target='_blank'>
                            {lang.BalancePage_TradeKeysBtn}
                        </a>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default BuyPage
