import Extensions from '../../models/extensions'
import { EServices } from './../tableHelper'

export class CurrencyModel {
	Symbol: string
	Value: number = 0
	IsLeft: boolean

	constructor(symbol: string, isLeft: boolean = false) {
		this.Symbol = symbol
		this.IsLeft = isLeft
	}
}

export class CurrencyData {
	public static readonly AllCurrencies: Map<string, CurrencyModel> = new Map([
		['USD', new CurrencyModel('$', true)],
		['EUR', new CurrencyModel('€')],
		['CNY', new CurrencyModel('¥', true)],
		['RUB', new CurrencyModel('₽')],
		['UAH', new CurrencyModel('₴', true)],
		['BYN', new CurrencyModel('Br', true)],
		['KZT', new CurrencyModel('₸')],
		['TRY', new CurrencyModel('₺')],
		['PLN', new CurrencyModel("zł")],
		['JPY', new CurrencyModel("¥", true)],
		['GBP', new CurrencyModel("£", true)],
		['AUD', new CurrencyModel("$", true)],
		["THB", new CurrencyModel("฿", true)],
		["NOK", new CurrencyModel("kr")],
		
		["AED", new CurrencyModel("د.إ")],
		["ARS", new CurrencyModel("$", true)],
		["BRL", new CurrencyModel("R$", true)],
		["CAD", new CurrencyModel("$", true)],
		["CHF", new CurrencyModel("₣", true)],
		["CLP", new CurrencyModel("$", true)],
		["COP", new CurrencyModel("$", true)],
		["CRC", new CurrencyModel("₡", true)],
		["HKD", new CurrencyModel("$", true)],
		["ILS", new CurrencyModel("₪")],
		["IDR", new CurrencyModel("Rp")],
		["INR", new CurrencyModel("₹")],
		["KRW", new CurrencyModel("₩")],
		["KWD", new CurrencyModel("KD")],
		["MXN", new CurrencyModel("$", true)],
		["MYR", new CurrencyModel("RM")],
		["NZD", new CurrencyModel("$", true)],
		["PEN", new CurrencyModel("S/.")],
		["PHP", new CurrencyModel("₱")],
		["QAR", new CurrencyModel("﷼")],
		["SAR", new CurrencyModel("﷼")],
		["SGD", new CurrencyModel("$", true)],
		["TWD", new CurrencyModel("NT$", true)],
		["UYU", new CurrencyModel("$U", true)],
		["VND", new CurrencyModel("₫")],
		["ZAR", new CurrencyModel("R")],
	])

	public static readonly CurrencyCodes: Map<EServices, string> = this.getCurrencyCodes()

	private static getCurrencyCodes(): Map<EServices, string> {
		const currencyCodes = new Map()

		for (let service of Extensions.GetEnumValues(EServices)) {
			currencyCodes.set(service, 'USD')
		}

		currencyCodes.set(EServices.SwapMarket, 'EUR')
		currencyCodes.set(EServices.Skinbaron, 'EUR')
		currencyCodes.set(EServices.Buff, 'CNY')
		currencyCodes.set(EServices.C5game, 'CNY')
		currencyCodes.set(EServices.Igxe, 'CNY')
		currencyCodes.set(EServices.TM, 'RUB')
		// currencyCodes.set(EServices.LisSkins, 'RUB')
		currencyCodes.set(EServices.TMNew, 'RUB')
		currencyCodes.set(EServices.SkinBid, 'EUR')
		currencyCodes.set(EServices.Youpin898, 'CNY')

		return currencyCodes
	}

	public static GetCurrency(service: EServices): string {
		return this.CurrencyCodes.get(service)
	}
}
